import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import ArticlePreview from '../../templates/articlePreview';
import {isMobile} from 'react-device-detect';
import cx from 'classnames';

class PopularPosts extends React.Component {

  constructor (props) {
    super(props);
    this.className = cx(
      'hidden w-[305px] lg:flex flex-col justify-between p-4 bg-gray-100',
      props.className
    );
    this.state = {
      displayTravelInsuranceAffiliate: ['travel-insurance'].includes(props.affiliateCategory)
    };
  }

  /*componentDidMount() {
      addTimeout(() => {
          rightSide('pt_rightcol_desktop')
      }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
  }*/

  renderItems () {
    return <StaticQuery
      query={graphql`
            query PopularPostsQuery {
                video: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {in: ["39161838"]}}}, website: {elemMatch: {name: {eq: "pt"}}}}
                    limit: 1
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPlugin
                    }
                }
                nonVideo: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {nin: ["39161838"]}}}, website: {elemMatch: {name: {eq: "pt"}}}}
                    limit: 2
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPlugin
                    }
                }
            }
        `}
      render={data =>
        data.nonVideo.nodes.concat(data.video.nodes)
          .map((article, index) => {
            return <ArticlePreview
              key={`top-right-${index}`} data={article}
              className="flex w-full mb-4"
              imageClass="mb-0 mr-2 float-left clear-both w-[70px] h-[70px]"
              gatsbyImageClass="w-[70px] h-[70px]"
              headingClass="font-outfit font-normal leading-snug text-base mb-0 line-clamp-3"
              noImage={true}
              noFlag={true}
              noBody={true}
              noAuthor={true}
              wrappedImg={false}
              dateAsFlag={true}
              showNumber={true}
              number={index + 1}
              authorAndReadingTime={true}
              noReadMore={true}
              titleAuthorDisplayClass={'flex-col-reverse'}
              publishedAtFormat={'mmmm dd, yyyy'}/>;
          })
      }
    />;
  }

  render () {
    return !isMobile &&
      <div className={'popular-posts article-popular relative ml-3 min-w-[305px]'}>
        <div className={this.className} data-datocms-noindex>
          {/*<CategoryTitle title="Popular Posts"/>*/}
          {this.renderItems()}
        </div>
        {/*{this.state.displayTravelInsuranceAffiliate && <div className={"text-center my-4"}>
                <a className={"affiliate-link affiliate-network-CJ affiliate-vendor-allianz"} href="https://www.tkqlhce.com/click-100596172-13938614" target="_top">
                    <img src="https://www.ftjcfx.com/image-100596172-13938614" width="305" height="250" alt=""/></a>
            </div>}*/}
        <div className="ad-container my-2 min-w-[305px]">
          <div className="advertisement-text">Advertisement</div>
          <div id="pt_rightcol_desktop"/>
        </div>
      </div>;
  }
}

export default PopularPosts;
